import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AnalitycsService {

  constructor() {
  }

  sendEvent = (category, label, action, value) => {
    console.log('send event');
    (<any>window).ga('send', 'event', {
      eventCategory: category,
      eventLabel: label,
      eventAction: action,
      eventValue: value
    });
  };

}
