<div *ngIf="staging" class="alert alert-danger text-center">SITO IN STAGING</div>
<header class="">
  <div class="container">
    <div class="row">
      <div class="col-6 col-md-3"><a [routerLink]="['/']" (click)="analytics.sendEvent('Click interni', 'From header', 'Home' , 1)"><img
        src="assets/images/general/logo-small.png" class="img-fluid" alt=""></a></div>
      <div class="col-6 col-md-9  text-right"><i class="fal fa-bars text-primary menu-icon mt-3"
                                                 (click)="openNav()"></i></div>
    </div>
  </div>
</header>
<!-- The overlay -->
<div id="myNav" class="overlay">

  <!-- Button to close the overlay navigation -->
  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>

  <!-- Overlay content -->
  <div class="overlay-content">
    <a class="menulink" href="/" (click)="analytics.sendEvent('Click interni', 'From menu', 'Home' , 1)">Home</a>
    <a class="menulink" href="/onboarding" (click)="analytics.sendEvent('Click interni', 'From menu', 'Gioca' , 1)">Gioca</a>
    <a class="menulink" href="/musei" (click)="analytics.sendEvent('Click interni', 'From menu', 'Musei' , 1)">I Musei</a>
    <span class="mt-5 d-inline-block p-5 text-white">Sei un museo e vuoi partecipare? <br>scrivi a savetheculture@heritage-srl.it</span>
    <br>
    <small class="text-white">Save the culture è un'iniziativa di <a href="htts://www.heritage-srl.it" (click)="analytics.sendEvent('linkUscita', 'Click Heritage', 'website' , 1)"> Heritage
      S.r.l.</a> <br> in collaborazione con il <a href="http://www.museotattilevarese.it/" target="_blank"> Museo
    Tattile Varese</a> </small>
  </div>

</div>
