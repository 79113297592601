import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OnBoardingService implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      const boardingComplete = localStorage.getItem('onBoarding');
      if (boardingComplete && boardingComplete === 'complete')  {
        return true;
      } else {
        this.router.navigate(['/gioca']);
      }
  }
  constructor(private router: Router) { }
}
