import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ExperienceConfigService implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      const experienceConfig = localStorage.getItem('experienceConfig');
      if (experienceConfig)  {
        return true;
      } else {
        this.router.navigate(['/experience-config']);
      }
  }
  constructor(private router: Router) { }
}
