<footer class="d-flex align-items-center">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12 text-center">
        <span class="small text-muted">Save the culture è un'iniziativa di <a href="https://www.heritage-srl.it"
                                                                              class="btn-link text-primary"

                                                                              target="_blank" title="Heritage S.r.l.">Heritage S.r.l.</a> | C.F. e P. IVA 10984990019  </span>
      </div>
      <div class="col-md-6 col-12 text-center">
        <span class="small text-muted">
          <img src="assets/images/general/interactive-culture-experience-logo.png"
               width="50" alt="ICX" class="mr-2">Powered by Interactive Cultural Experience - An Heritage S.r.l. product</span>
      </div>
    </div>
  </div>
</footer>
