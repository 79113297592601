import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnBoardingService } from './services/onboarding.service';
import { ExperienceConfigService } from './services/experience-config.service';

const routes: Routes = [
 /* {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(mod => mod.HomeModule)
  },*/
   {
     path: '',
     loadChildren: () => import('./pages/gioca/gioca.module').then(mod => mod.GiocaModule)
   },
 /* {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(mod => mod.HomeModule)
  },*/
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then(mod => mod.OnboardingModule)
  },
  {
    path: 'musei',
    loadChildren: () => import('./pages/musei/musei.module').then(mod => mod.MuseiModule)
  },
 /* {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then(mod => mod.AboutModule)
  },*/
  {
    path: 'experience-config',
    loadChildren: () => import('./pages/experience-config/experience-config.module').then(mod => mod.ExperienceConfigModule),
    canActivate: [OnBoardingService]
  },
  {
    path: 'experience',
    loadChildren: () => import('./pages/experience/experience.module').then(mod => mod.ExperienceModule),
    canActivate: [ExperienceConfigService]
  },
 {
    path: 'gioca',
    loadChildren: () => import('./pages/gioca/gioca.module').then(mod => mod.GiocaModule),
  },
  {
    path: '*',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
