import {Component, OnInit} from '@angular/core';
import {AnalitycsService} from '../../services/analitycs.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public staging = environment.staging;

  constructor(public analytics: AnalitycsService) {
  }


  ngOnInit() {
  }

  openNav() {
    document.getElementById('myNav').style.width = '100%';
  }

  closeNav() {
    document.getElementById('myNav').style.width = '0%';
  }

}
